// import styled from '@emotion/styled';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const CONTENT_STYLE = { color: '#440088' };
const ICON_STYLE = { background: '#440088' };

// https://github.com/stephane-monnot/react-vertical-timeline
// Would like the round dots to become elongated and to allow multiple overlapping
// elements, but hey...
export const Timeline = () => {
  return (
    <VerticalTimeline lineColor='#440088'>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={CONTENT_STYLE}
        date="Jan 2000 - Mar 2003"
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Agilent Technologies</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          C, C++
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Apr 2003 - Jan 2005"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">MTData</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          C, C#
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Feb 2005 - Feb 2007"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">DWS (Origin Energy)</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, SQL, PL/SQL
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Feb 2007 - Feb 2008"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Volutio</h3>
        <h4 className="vertical-timeline-element-subtitle">Chippenham, UK</h4>
        <p>
          Java, JavaScript
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Feb 2008 - Jun 2008"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Staellium</h3>
        <h4 className="vertical-timeline-element-subtitle">London, UK</h4>
        <p>
          Java, JavaScript
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Jul 2008 - Dec 2009"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Icom</h3>
        <h4 className="vertical-timeline-element-subtitle">London, UK</h4>
        <p>
          Java, JavaScript, SQL
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Dec 2009 - Dec 2010"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Reece</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, SQL
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Jan 2011 - Aug 2011"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Sensis</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, JavaScript
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Sep 2011 - Feb 2013"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Open Universities Australia</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, JavaScript
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Mar 2013 - Mar 2015"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Australia Post Digital Mailbox</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, JavaScript, Scala, Clojure
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Mar 2015 - Mar 2016"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Sensis</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Java, JavaScript, Scala, MongoDB
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Mar 2016 - Jan 2018"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Bridge</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          JavaScript, Scala, MongoDB, GraphQL
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Feb 2018 - Sep 2018"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Shroogal</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          JavaScript, GraphQL, DynamoDB, AWS
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Sep 2018 - Aug 2019"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">Homepass</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          JavaScript, GraphQL, MongoDB, AWS
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Aug 2019 - Present"
        contentStyle={CONTENT_STYLE}
        iconStyle={ICON_STYLE}
      >
        <h3 className="vertical-timeline-element-title">OVO Energy Australia</h3>
        <h4 className="vertical-timeline-element-subtitle">Melbourne, AU</h4>
        <p>
          Scala, TypeScript, GraphQL, MongoDB, AWS, Terraform
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  )
}